import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "在华为云部署高可用的dns服务"
    }}>{`在华为云部署高可用的DNS服务`}</h1>
    <h2 {...{
      "id": "需要的技术组件"
    }}>{`需要的技术组件`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`华为云弹性服务器`}</p>
        <p parentName="li">{`推荐使用广州-public里的`}<a parentName="p" {...{
            "href": "https://console.huaweicloud.com/ecm/?agencyId=05686a1c000010301f3fc012b4130b9f&locale=zh-cn&region=cn-south-1_public#/ims/manager/imsSelfDetail/shareMembers?imageType=systemDiskImage&imageId=a12c349e-243a-49c3-855d-dd0b28bfab31"
          }}>{`“CentOS-7.6-Docker-JDK-LVM-SWAP”私有镜像`}</a>{` 申请2台服务器创建：私有镜像基于CentOS 7.6（x86_64-Minimal-1810）的`}<a parentName="p" {...{
            "href": "https://repo.huaweicloud.com/centos/7/isos/x86_64/CentOS-7-x86_64-Minimal-1810.iso"
          }}>{`ISO光盘`}</a>{`部署，默认配置了`}<a parentName="p" {...{
            "href": "https://support.huaweicloud.com/trouble-ecs/zh-cn_topic_0175420638.html"
          }}>{`SAWP分区`}</a>{`，按LVM分区（`}<a parentName="p" {...{
            "href": "https://www.cnblogs.com/CGCong/p/9375787.html"
          }}>{`Logical_Volume_Manager`}</a>{`方便日后服务运行时可以系统不停机对分区扩容），安装了JDK 1.8 ，Docker 18.0，Docker-compose 1.14.0 ，软件源使用`}<a parentName="p" {...{
            "href": "https://mirrors.huaweicloud.com/"
          }}>{`华为开源镜像站`}</a>{`，参照《`}<a parentName="p" {...{
            "href": "https://support.huaweicloud.com/bestpractice-ims/zh-cn_topic_0107074486.html"
          }}>{`基于VirtualBox使用ISO创建Linux镜像`}</a>{`》创建。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`华为云对象存储`}</p>
        <p parentName="li">{`在华为云`}<a parentName="p" {...{
            "href": "https://storage.huaweicloud.com/obs"
          }}>{`对象存储控制台`}</a>{`中建立 `}<a parentName="p" {...{
            "href": "https://storage.huaweicloud.com/obs/?agencyId=05686a1c000010301f3fc012b4130b9f&region=cn-south-1_public&locale=zh-cn#/obs/manage/obs-app-backup/summary"
          }}>{`obs-app-backup桶`}</a>{`，用来存储2台DNS服务器的共有配置文件（`}<a parentName="p" {...{
            "href": "https://storage.huaweicloud.com/obs/?agencyId=05686a1c000010301f3fc012b4130b9f&region=cn-south-1_public&locale=zh-cn#/obs/manage/obs-app-backup/object/list?prefix=dnsmasq%2F"
          }}>{`dnsmasq.conf`}</a>{`）。
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`域名解析服务`}</p>
        <p parentName="li">{` 使用dnsmasq对应的docker版本jpillora/dnsmasq。提供简单的web界面可进行DNS的配置管理、日志查看等操作。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`反向代理服务`}</p>
        <p parentName="li">{` Nginx 版本1.16.0 ，反向代理功能需要支持stream模块。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`高可用软件`}</p>
        <p parentName="li">{` Keepalived是基于vrrp协议的一款高可用软件，为两台服务器提供一个共同的虚拟ip---VIP，平时VIP在主服务器上，当主服务宕机，VIP就跑到备用服务器上，引导客户端访问流量到备用服务器，从而实现高可用。Keepalived的HA分为抢占模式和非抢占模式，抢占模式即MASTER从故障中恢复后，会将VIP从BACKUP节点中抢占过来。非抢占模式即MASTER恢复后不抢占BACKUP升级为MASTER后的VIP。 本例中采用非抢占模式，这样会避免VIP切换可能造成的服务延迟。`}</p>
      </li>
    </ul>
    <p><img alt="1190037-20171217170645561-1428317110.png" src={require("./N00000001566896320304@1190037-20171217170645561-1428317110.png")} /></p>
    <h2 {...{
      "id": "挂载obs-app-backup桶在2台服务器上"
    }}>{`挂载obs-app-backup桶(在2台服务器上)`}</h2>
    <p>{`   参考`}<a parentName="p" {...{
        "href": "https://git.sgmw.com.cn/chenxixian/obs-newbaojunapp-prod"
      }}>{`《新宝骏App的对象存储使用方法》`}</a>{`，挂载obs-app-backup桶到/dns ，可以使用本项目中的`}<a parentName="p" {...{
        "href": "https://git.sgmw.com.cn/chenxixian/ha-dns/blob/master/mount-obsfs.sh"
      }}>{`mount-obsfs.sh`}</a>{`文件（需要自行更新AK/SK）。`}</p>
    <h2 {...{
      "id": "部署dnsmasq在2台服务器上"
    }}>{`部署Dnsmasq(在2台服务器上)`}</h2>
    <h3 {...{
      "id": "创建简单的配置-dnsdnsmasqdnsmasqconf-："
    }}>{`创建简单的配置 /dns/dnsmasq/dnsmasq.conf ：`}</h3>
    <pre><code parentName="pre" {...{}}>{`#输出查询日志信息
log-queries
#do not use hosts nameservers
#默认会使用网关server,若需要配置本地局域网自动使用使用本DNS服务，则须将此选项设置为NO
no-resolv 
#配置使用的服务，即本地查询不到时，可通过此服务依次进行查询解析，可配置多个，一般为已知的或代理的外网DNS服务
server=100.125.1.250
server=100.125.136.29
server=223.5.5.5
#server=/syq/192.168.248.137
#explicitly define host-ip mappings
address=/dns.sgmw.com.cn/10.14.2.222
`}</code></pre>
    <h3 {...{
      "id": "运行容器："
    }}>{`运行容器：`}</h3>
    <pre><code parentName="pre" {...{}}>{`docker run \\
    --name dnsmasq \\
    -d \\
    -p 53:53/udp \\
    -p 8080:8080 \\
    -v /dns/dnsmasq/dnsmasq.conf:/etc/dnsmasq.conf \\
    -v /etc/localtime:/etc/localtime:ro \\
    -e "HTTP_USER=***" \\
    -e "HTTP_PASS=***" \\
    --restart always \\
    jpillora/dnsmasq
`}</code></pre>
    <h3 {...{
      "id": "访问web-ui"
    }}>{`访问web UI`}</h3>
    <pre><code parentName="pre" {...{}}>{`访问http://dns-host:8080，使用帐号/密码登录，进入类似如下界面，可直接修改配置，点击save即可快速生效。
`}</code></pre>
    <p><img alt="20181127102647925.png" src={require("./N00000001566896320304@20181127102647925.png")} /></p>
    <h2 {...{
      "id": "部署nginx在2台服务器上"
    }}>{`部署Nginx(在2台服务器上)`}</h2>
    <h3 {...{
      "id": "安装nginx"
    }}>{`安装Nginx`}</h3>
    <pre><code parentName="pre" {...{}}>{`  wget  http://nginx.org/packages/centos/7/noarch/RPMS/nginx-release-centos-7-0.el7.ngx.noarch.rpm
  rpm -ivh nginx-release-centos-7-0.el7.ngx.noarch.rpm
  yum install nginx -y
  systemctl start nginx
  systemctl enable nginx
`}</code></pre>
    <h3 {...{
      "id": "配置nginx"
    }}>{`配置Nginx`}</h3>
    <pre><code parentName="pre" {...{}}>{`  cd /etc/nginx/conf.d/
  cp nginx.conf nginx.conf.bak
  nano nginx.conf
`}</code></pre>
    <h3 {...{
      "id": "反向代理配置，复制到nginxconf："
    }}>{`反向代理配置，复制到nginx.conf：`}</h3>
    <pre><code parentName="pre" {...{}}>{`#user nobody;
worker_processes 4;
events {
worker_connections 1024;
}
stream {
upstream dns {
server 10.14.2.21:5353 weight=1;
server 10.14.2.63:5353 weight=1;
}
server {
listen 53 udp;
proxy_connect_timeout 1s;
proxy_timeout 20s;
proxy_pass dns;
}
}

`}</code></pre>
    <h3 {...{
      "id": "启用配置"
    }}>{`启用配置`}</h3>
    <pre><code parentName="pre" {...{}}>{`  nginx -t
  nginx -s reload
`}</code></pre>
    <h2 {...{
      "id": "keepalived在2台服务器上"
    }}>{`Keepalived(在2台服务器上)`}</h2>
    <h3 {...{
      "id": "系统环境准备"
    }}>{`系统环境准备`}</h3>
    <pre><code parentName="pre" {...{}}>{`systemctl stop firewalld.service
systemctl disable firewalld.service
sed -i 's#SELINUX=enforcing#SELINUX=disabled#g' /etc/selinux/config
setenforce 0

`}</code></pre>
    <h3 {...{
      "id": "安装keepalived"
    }}>{`安装Keepalived`}</h3>
    <pre><code parentName="pre" {...{}}>{`  yum install keepalived -y
  mkdir /etc/keepalived
  nano /etc/keepalived/keepalived.conf
`}</code></pre>
    <h3 {...{
      "id": "主节点配置"
    }}>{`主节点配置`}</h3>
    <pre><code parentName="pre" {...{}}>{`! Configuration File for keepalived
global_defs {
    router_id node_01
}
vrrp_script chk_nginx {
    script "killall -0 nginx && netstat -an | grep :::5353"
    interval 1
    weight - 5
    fall    3
    rise    5
}
vrrp_instance VI_1 {
    state MASTER
    interface eth0
    virtual_router_id 88 
        priority 100 
        advert_int 1 
    nopreempt
    authentication {
        auth_type PASS
        auth_pass 8888
    }
    virtual_ipaddress {
        10.14.2.222
    }
    track_script {
        chk_nginx
    }
}

`}</code></pre>
    <h3 {...{
      "id": "备节点配置"
    }}>{`备节点配置`}</h3>
    <pre><code parentName="pre" {...{}}>{`! Configuration File for keepalived
global_defs {
    router_id node_02
}
vrrp_script chk_nginx {
    script "killall -0 nginx && netstat -an | grep :::5353"
    interval 1
    weight - 5
    fall    3
    rise    5
}
vrrp_instance VI_1 {
    state BACKUP
    interface eth0
    virtual_router_id 88 
        priority 99 
        advert_int 1 
    nopreempt
    authentication {
        auth_type PASS
        auth_pass 8888
    }
    virtual_ipaddress {
        10.14.2.222
    }
    track_script {
        chk_nginx
    }
}

`}</code></pre>
    <h3 {...{
      "id": "启用配置-1"
    }}>{`启用配置`}</h3>
    <pre><code parentName="pre" {...{}}>{`  systemctl start keepalived 
  systemctl enable keepalived
  systemctl status keepalived -l
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      